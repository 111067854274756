import { isDemoEnv, isLocalDevelopment, isStagingEnv } from 'helpers/env';

const baseURI = {
  dev: 'https://services-dev.thecoursekey.com/payments',
  prod: 'https://services.thecoursekey.com/payments',
};

export const PAYMENT_BASE_URL =
  baseURI[isStagingEnv || isDemoEnv || isLocalDevelopment ? 'dev' : 'prod'];

export const LIST_PAYMENT_PLAN_API_URL = `${PAYMENT_BASE_URL}/v1/paymentPlan`;
export const PAYMENT_SCHEDULE_API_URL = `${PAYMENT_BASE_URL}/v1/user/{userId}/paymentPlan`;
export const PAYMENT_METHOD_API_URL = `${PAYMENT_BASE_URL}/v1/user/{userId}/paymentMethod`;
export const PAYMENT_PLAN_DISABLE_API_URL = `${PAYMENT_BASE_URL}/v1/paymentPlan/disable`;
export const INVOICE_LIST_API_URL = `${PAYMENT_BASE_URL}/v1/user/{userId}/invoice`;
export const INVOICE_DETAIL_API_URL = `${PAYMENT_BASE_URL}/v1/user/{userId}/invoice/{invoiceId}`;
export const SEND_PAYMENT_LINK_API_URL = `${PAYMENT_BASE_URL}/v1/user/{userId}/invoice/{invoiceId}/sendPaymentLink`;
export const PAYMENT_CHARGE_API_URL = `${PAYMENT_BASE_URL}/v1/charge`;
export const GET_TRANSACTION_ADMIN_API_URL = `${PAYMENT_BASE_URL}/v1/transaction/{transactionID}`;
export const GET_TRANSACTION_STUDENT_API_URL = `${PAYMENT_BASE_URL}/v1/user/{userID}/transaction/{transactionID}`;
export const TRANSACTION_LIST_ADMIN_API_URL = `${PAYMENT_BASE_URL}/v1/transaction`;
export const TRANSACTION_LIST_STUDENT_API_URL = `${PAYMENT_BASE_URL}/v1/user/{userID}/transaction`;
export const GET_MERCHANTS_TIER_API_URL = `${PAYMENT_BASE_URL}/v1/merchantTiers`;
export const CREATE_MERCHANT_API_URL = `${PAYMENT_BASE_URL}/v1/merchant`;
export const GET_MERCHANT_PAYMENT_API_URL = `${PAYMENT_BASE_URL}/v1/school/{schoolID}/paymentConfig`;
export const PAY_INVOICE_API_URL = `${PAYMENT_BASE_URL}/v1/invoice/{invoiceID}/pay`;
export const ALL_STUDENT_INVOICE_LIST_API_URL = `${PAYMENT_BASE_URL}/v1/invoice`;
export const ALL_STUDENT_INVOICE_DETAIL_API_URL = `${PAYMENT_BASE_URL}/v1/invoice/{invoiceId}`;
export const SEND_PAYMENT_REMINDER_API_URL = `${PAYMENT_BASE_URL}/v1/invoice/paymentReminder`;
export const GET_ALL_TRANSACTION_API_URL = `${PAYMENT_BASE_URL}/v1/transaction`;
export const FINANCE_SUMMARY_API_URL = `${PAYMENT_BASE_URL}/v1/finance/summary`;
export const GET_ALL_STUDENT_PAYMENT_BREAKDOWN_API_URL = `${PAYMENT_BASE_URL}/v1/insights/studentPaymentBreakdown`;
export const GET_ALL_STUDENT_PAYMENT_BY_PROGRAM_API_URL = `${PAYMENT_BASE_URL}/v1/insights/studentPaymentByProgram`;
export const SEND_REMINDER = `${PAYMENT_BASE_URL}/v1/user/{userID}/paymentReminder`;
export const UPDATE_PAYMENT_PLAN_API_URL = `${PAYMENT_BASE_URL}/v2/user/{userId}/paymentPlan/{paymentPlanId}`;
export const REPORTS_API_URL = `${PAYMENT_BASE_URL}/v1/report`;
export const AUTOMATION_TRIGGERS_API_URL = `${PAYMENT_BASE_URL}/v1/school/{schoolID}/automation/{triggerType}`;
export const AUTOMATION_ACTIVITY_API_URL = `${PAYMENT_BASE_URL}/v1/school/{schoolID}/automation/activity`;
export const EXPORT_AUTOMATION_ACTIVITY_API_URL = `${PAYMENT_BASE_URL}/v1/school/{schoolID}/automation/export`;
export const REFUND_TRANSACTION_API_URL = `${PAYMENT_BASE_URL}/v1/transaction/{transactionID}/refund`;
export const PROCESSING_FEE_API_URL = `${PAYMENT_BASE_URL}/v1/school/{schoolID}/paymentFees`;
export const GET_ALL_BLLINGCONTACT = `${PAYMENT_BASE_URL}/v1/paymentPlan/{paymentPlanID}/billingContact`;
export const GET_BILLINGCONTACT = `${PAYMENT_BASE_URL}/v1/paymentPlan/{paymentPlanID}/billingContact/{billingContactID}`;
export const DOWNLOAD_INVOICE_API_URL = `${PAYMENT_BASE_URL}/v1/invoice/{invoiceID}/download`;
export const GET_ALL_PAYMENT_SCHEDULE_API_URL = `${PAYMENT_BASE_URL}/v1/paymentPlan`;
export const GET_PAYMENT_TEMPLATES = `${PAYMENT_BASE_URL}/v1/template`;
export const ADD_TEMPLATES = `${PAYMENT_BASE_URL}/v1/template`;
export const UPDATE_TEMPLATES = `${PAYMENT_BASE_URL}/v1/template/{templateID}`;
export const PAYMENT_SCHEDULE_BULK_CREATE = `${PAYMENT_BASE_URL}/v1/paymentPlan/bulkCreate`;
export const DELETE_TEMPLATE = `${PAYMENT_BASE_URL}/v1/template/{templateID}`;
export const GET_ALL_INVOICE_FEES_API_URL = `${PAYMENT_BASE_URL}/v1/invoice/{invoiceId}/fee`;
export const GET_INVOICE_FEE_API_URL = `${PAYMENT_BASE_URL}/v1/invoice/{invoiceId}/fee/{invoiceFeeId}`;
export const GET_LIST_PAYMENT_PLAN_API_URL = `${PAYMENT_BASE_URL}/v1/paymentPlan/{paymentPlanID}`;
export const LIST_USER_WITH_PROGRAMS_API_URL = `${PAYMENT_BASE_URL}/v1/userProgram`;
export const UPDATE_DAILY_TRANSACTION_SUMMARY_API_URL = `${PAYMENT_BASE_URL}/v1/user/{userId}/schoolAdminPaymentConfig`;
export const GET_DAILY_TRANSACTION_SUMMARY_API_URL = `${PAYMENT_BASE_URL}/v1/user/{userId}/schoolAdminPaymentConfig`;
export const SEND_PAYMENT_LINK_TO_STUDENTS = `${PAYMENT_BASE_URL}/v1/paymentLinkTransaction`;
export const RESEND_PAYMENT_LINK_TO_STUDENTS = `${PAYMENT_BASE_URL}/v1/paymentLinkTransaction/{transactionID}/resend`;
export const REFUND_PAYMENT_LINK_TRANSACTION_API_URL = `${PAYMENT_BASE_URL}/v1/paymentLinkTransaction/{transactionID}/refund`;
export const DELETE_PAYMENT_LINK_TRANSACTION_API_URL = `${PAYMENT_BASE_URL}/v1/paymentLinkTransaction/{transactionID}`;
export const GET_SCHOOL_PAYMENT_CONFIG_API_URL = `${PAYMENT_BASE_URL}/v1/school/listPaymentConfig`;
