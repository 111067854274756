import axios from 'actions';

import type {
  CreateNudgeSchoolContactsParams,
  NudgeHolidaysParams,
  NudgeHoursParams,
} from 'tools/types/nudge/nudgeSettings';

// Campus Logo
export const uploadCampusLogo = (schoolID: string, body: FormData) =>
  axios
    .post(`/api/v1/nudge/settings/${schoolID}/logo`, body)
    .catch(({ response }) => Promise.reject(response.data.error));

export const getCampusLogo = (schoolID: string) =>
  axios
    .get(`/api/v1/nudge/settings/${schoolID}/logo`, {
      responseType: 'blob',
    })
    .then(({ data }) => {
      return window.URL.createObjectURL(data);
    })
    .catch(() => null);

export const deleteCampusLogo = (schoolID: string) =>
  axios
    .delete<void>(`/api/v1/nudge/settings/${schoolID}/logo`)
    .catch(({ response }) => Promise.reject(response.data.error));

// School Contacts
export const createSchoolContact = (
  schoolID: string,
  params: CreateNudgeSchoolContactsParams,
) => {
  const trimmedParams = {
    ...params,
    firstName: params.firstName.trim(),
    lastName: params.lastName.trim(),
    title: params.title.trim(),
  };
  return axios
    .post<void>(`/api/v1/nudge/settings/${schoolID}/contact`, trimmedParams)
    .catch(({ response }) => Promise.reject(response.data.error));
};

export const updateSchoolContact = (
  schoolID: string,
  contactId: string,
  params: CreateNudgeSchoolContactsParams,
) => {
  const trimmedParams = {
    ...params,
    firstName: params.firstName.trim(),
    lastName: params.lastName.trim(),
    title: params.title.trim(),
  };
  return axios
    .put<void>(
      `/api/v1/nudge/settings/${schoolID}/contact/${contactId}`,
      trimmedParams,
    )
    .catch(({ response }) => Promise.reject(response.data.error));
};

export const deleteSchoolContact = (schoolID: string, contactId: string) =>
  axios
    .delete<void>(`/api/v1/nudge/settings/${schoolID}/contact/${contactId}`)
    .catch(({ response }) => Promise.reject(response.data.error));

// Nudge Hours
export const updateNudgeHours = (schoolID: string, params: NudgeHoursParams) =>
  axios
    .put<void>(`/api/v1/nudge/settings/${schoolID}/hours`, params)
    .catch(({ response }) => Promise.reject(response.data.error));

// School Holidays
export const deleteSchoolHoliday = (schoolID: string, holidayID: string) =>
  axios
    .delete<void>(`/api/v1/nudge/settings/${schoolID}/holiday/${holidayID}`)
    .catch(({ response }) => Promise.reject(response.data.error));

export const createSchoolHoliday = (
  schoolID: string,
  params: NudgeHolidaysParams,
) => {
  const trimmedParams = {
    ...params,
    title: params.title.trim(),
  };
  return axios
    .post<void>(`/api/v1/nudge/settings/${schoolID}/holiday`, trimmedParams)
    .catch(({ response }) => Promise.reject(response.data.error));
};

export const updateSchoolHoliday = (
  schoolID: string,
  holidayID: string,
  params: NudgeHolidaysParams,
) => {
  const trimmedParams = {
    ...params,
    title: params.title.trim(),
  };
  return axios
    .put<void>(
      `/api/v1/nudge/settings/${schoolID}/holiday/${holidayID}`,
      trimmedParams,
    )
    .catch(({ response }) => Promise.reject(response.data.error));
};

// Apply
export const applyCampuses = (
  schoolID: string,
  schoolIDs: string[],
  entity: string,
) =>
  axios
    .patch<void>(`/api/v1/nudge/settings/${schoolID}/${entity}/apply`, {
      destinationSchoolIDs: schoolIDs,
    })
    .catch(({ response }) => Promise.reject(response.data.error));
