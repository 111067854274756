import type { ISOString } from '.';
import type { ThemeMode } from '../constants/profile';
import type { HealthCheck } from './healthCheck';
import type { SchoolAccount } from './school';
import type { Workspace } from './workspaces';

export interface Cortex {
  canEditWorkspaces: boolean;
  encryptedClaims: string;
  gdWorkspaceID: string;
  ssoProvider: string;
  workspaces: Array<Workspace>;
}

export const PermissionValues = {
  AccessCourses: 'accessCourses',
  AccessFinance: 'accessFinance',
  AccessForms: 'accessForms',
  AccessPaymentSettings: 'accessPaymentSettings',
  AddInvoiceFees: 'addInvoiceFees',
  ChangePassword: 'changePassword',
  CompleteMultipleSchoolPaymentApplication:
    'completeMultipleSchoolPaymentApplication',
  CompleteSchoolPaymentApplication: 'completeSchoolPaymentApplication',
  CreateCourse: 'createCourse',
  CreateMakeup: 'createMakeup',
  CreatePaymentSchedules: 'createPaymentSchedules',
  CreatePaymentTemplate: 'createPaymentTemplate',
  DeleteInvoiceFees: 'deleteInvoiceFees',
  DeletePaymentSchedules: 'deletePaymentSchedules',
  DownloadFinanceReports: 'downloadFinanceReports',
  EditCourse: 'editCourse',
  EditDashboard: 'editDashboard',
  EditExternalContacts: 'editExternalContacts',
  EditForms: 'editForms',
  EditGrades: 'editGrades',
  EditNudge: 'editNudge',
  EditPaymentInvoice: 'editPaymentInvoice',
  EditPaymentSchedules: 'editPaymentSchedules',
  EditPaymentSettings: 'editPaymentSettings',
  EditPaymentTemplate: 'editPaymentTemplate',
  EditPrograms: 'editPrograms',
  EditUser: 'editUser',
  EditWorkspaces: 'editWorkspaces',
  Export: 'export',
  Integration: 'integration',
  IssuePaymentRefund: 'issuePaymentRefund',
  MakePaymentOnBehalfOfStudent: 'makePaymentOnBehalfOfStudent',
  ManageLocations: 'manageLocations',
  SchoolManagement: 'schoolManagement',
  SendPaymentLinkOnBehalfOfStudent: 'sendPaymentLinkOnBehalfOfStudent',
  SetupPaymentNotifications: 'setupPaymentNotifications',
  TurnOffAutoPay: 'turnOffAutoPay',
  UsePaymentTemplate: 'usePaymentTemplate',
  ViewContentBank: 'viewContentBank',
  ViewDashboard: 'viewDashboard',
  ViewExternalContacts: 'viewExternalContacts',
  ViewFinanceDashboard: 'viewFinanceDashboard',
  ViewNudge: 'viewNudge',
  ViewPaymentTransactions: 'viewPaymentTransactions',
  ViewStudentManagement: 'viewStudentManagement',
  ViewWorkspaces: 'viewWorkspaces',
} as const;
export type Permission = typeof PermissionValues[keyof typeof PermissionValues];

export type Permissions = Record<Permission, boolean>;

export interface SchoolPermission extends Permissions {
  isIntegrated: boolean;
  isManagedContent: boolean;
  SchoolAdminPermissionsID: number;
  schoolID: string;
  schoolName: string;
  schoolTimezone?: string | null;
  userID: number;
}

export interface RotationManagement {
  allowedSchools: Array<string>;
  enabled: boolean;
}

export interface UserRole {
  roleID: number;
  roleName: UserRoleName;
}

export enum UserRoleName {
  SiteCertifier = 'SITE_CERTIFIER',
}

export interface Profile {
  accounts: Array<SchoolAccount>;
  cortex?: Cortex;
  createdAt: ISOString;
  defaultToSchoolTimezone: boolean;
  email: string;
  firstName: string;
  hasAgreedToTerms: boolean;
  hasLicense: boolean;
  hasNotification: boolean;
  hasPrograms: boolean;
  hasRetentionDashboard: boolean;
  healthCheck: HealthCheck;
  isActive: boolean;
  isSchoolAdmin: boolean;
  isSupport: boolean;
  isTeacher: boolean;
  isVerify: boolean;
  lastName: string;
  managedContent: boolean;
  mobileNumber: string;
  privacyPolicy: string;
  rotationManagement: RotationManagement;
  schoolPermissions?: Array<SchoolPermission>;
  subscription: string | null;
  termsOfService: string;
  theme: ThemeMode;
  updatedAt: ISOString;
  userID: number;
  userRoles: Array<UserRole>;
  userTimezone: string | null;
}
