import { Box, TextField, type TextFieldProps, Typography } from '@mui/material';
import { TimePicker, TimePickerProps } from '@mui/x-date-pickers-pro';
import { DateTime } from 'luxon';
import { forwardRef } from 'react';

import { useTimezoneContext } from 'components/timezones';

interface LocatedTimePickerProps
  extends Omit<TimePickerProps<Date, Date>, 'renderInput'> {
  showCurrentTimeZone?: boolean;
  TextFieldProps?: TextFieldProps;
}

const LocatedTimePicker = forwardRef(function LocatedTimePicker({
  TextFieldProps,
  showCurrentTimeZone = true,
  ...props
}: LocatedTimePickerProps) {
  const { currentTimeZoneAbbreviation } = useTimezoneContext();

  /**
   * This is a hack to get around the fact that the MUI DatePicker component requires luxon DateTime objects after our new implementation
   * @todo change all teh applications to use luxon DateTime objects
   */
  const normalizedProps: Omit<
    TimePickerProps<DateTime, DateTime>,
    'renderInput'
  > = {
    ...props,

    /**
     * @todo Need to support getClockLabelText property. Marked as a follow up cause its more complex
     */
    getClockLabelText: undefined,

    /**
     * @todo Need to support getOpenDialogAriaText property. Marked as a follow up cause its more complex
     */
    getOpenDialogAriaText: undefined,

    maxTime: props.maxTime && DateTime.fromJSDate(props.maxTime),
    minTime: props.minTime && DateTime.fromJSDate(props.minTime),

    onAccept: (date) => {
      return props.onAccept?.(date?.toJSDate() ?? null);
    },
    onChange: (date, keyboardInputValue) => {
      return props.onChange(date?.toJSDate() ?? null, keyboardInputValue);
    },
    onError: (reason, value) => {
      return props.onError?.(reason, value?.toJSDate() ?? null);
    },

    /**
     * @todo Need to support openTo property. Marked as a follow up cause its more complex
     */
    ToolbarComponent: undefined,
    value: props.value ? DateTime.fromJSDate(new Date(props.value)) : null,
  };
  return (
    <TimePicker
      {...normalizedProps}
      renderInput={(props) => (
        <TextField
          {...TextFieldProps}
          {...props}
          InputProps={{
            ...TextFieldProps?.InputProps,
            ...props.InputProps,
            endAdornment: (
              <Box alignItems="center" display="flex" gap={0}>
                {showCurrentTimeZone && (
                  <Typography
                    fontStyle="italic"
                    variant="caption"
                    whiteSpace="nowrap"
                  >
                    {currentTimeZoneAbbreviation}
                  </Typography>
                )}
                {props.InputProps?.endAdornment}
              </Box>
            ),
          }}
        />
      )}
      // timezone={timezone}
    />
  );
});

export default LocatedTimePicker;
