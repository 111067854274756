import { Backdrop, Box, CircularProgress } from '@mui/material';

export const SPINNER_Z_INDEX = 9999;

interface SpinnerProps {
  position?: 'absolute' | 'relative';
  showBackdrop?: boolean;
  size?: number;
  zIndex?: number;
}

const Spinner = ({
  position = 'absolute',
  showBackdrop = false,
  size = 48,
  zIndex = SPINNER_Z_INDEX,
}: SpinnerProps) => {
  const spinnerContent = (
    <Box
      alignItems="center"
      display="flex"
      height="100%"
      justifyContent="center"
      left={0}
      position={position}
      top={0}
      width="100%"
      zIndex={zIndex}
    >
      <CircularProgress size={size} thickness={3} />
    </Box>
  );

  return showBackdrop ? (
    <Backdrop open sx={{ zIndex }}>
      {spinnerContent}
    </Backdrop>
  ) : (
    spinnerContent
  );
};

export default Spinner;
