import { axiosServerless } from 'actions';

import {
  ADD_TEMPLATES,
  CREATE_MERCHANT_API_URL,
  DELETE_PAYMENT_LINK_TRANSACTION_API_URL,
  DELETE_TEMPLATE,
  GET_DAILY_TRANSACTION_SUMMARY_API_URL,
  GET_LIST_PAYMENT_PLAN_API_URL,
  GET_MERCHANT_PAYMENT_API_URL,
  LIST_PAYMENT_PLAN_API_URL,
  PAYMENT_CHARGE_API_URL,
  PAYMENT_METHOD_API_URL,
  PAYMENT_PLAN_DISABLE_API_URL,
  PAYMENT_SCHEDULE_API_URL,
  PAYMENT_SCHEDULE_BULK_CREATE,
  REFUND_PAYMENT_LINK_TRANSACTION_API_URL,
  REFUND_TRANSACTION_API_URL,
  REPORTS_API_URL,
  SEND_REMINDER,
  UPDATE_DAILY_TRANSACTION_SUMMARY_API_URL,
  UPDATE_PAYMENT_PLAN_API_URL,
  UPDATE_TEMPLATES,
} from 'tools/urls/payment';

import type {
  autoPayRequest,
  autoPayRequestParams,
  DailySummaryUpdateAPIRequest,
  DefaultPaymentMethodRequest,
  DefaultPaymentMethodRequestParams,
  deletePaymentMethodRequestParams,
  disablePaymentPlanRequest,
  makePaymentRequest,
  PaymentMethodCreateRequest,
  PaymentMethodCreateRequestParams,
  PaymentPlanAPIRequest,
  PaymentPlanAPIRequestParams,
  PaymentPlanCancelRequest,
  PaymentPlanCancelRequestParams,
  PaymentPlanUpdateRequest,
  PaymentPlanUpdateRequestParams,
  PaymentScheduleAPIRequest,
  RefundTransactionAPIRequest,
  ReportsAPIRequest,
  SendReminderRequest,
  SendReminderRequestParams,
  userActivePaymentPlansRequest,
} from 'types/payment';
import type { TemplateAPIRequest } from 'types/template';

/**
 * Creates a payment schedule for student.
 * @param {PaymentPlanAPIRequestParams} params - The URL of the API endpoint to which the POST request will be sent.
 * @param {PaymentPlanAPIRequest} apiData - The data to be sent in the POST request body.
 * @returns {Promise<any>} A promise that resolves with the response data from the API.
 * @throws {Error} If there is an error during request.
 */
export const createPaymentSchedule = async (
  params: PaymentPlanAPIRequestParams,
  apiData: PaymentPlanAPIRequest,
) => {
  try {
    const apiUrl = PAYMENT_SCHEDULE_API_URL.replace('{userId}', params.userID);
    return await axiosServerless.post(apiUrl, apiData);
  } catch (error) {
    return Promise.reject(error?.response?.data || error);
  }
};

/**
 * Update a payment schedule for student.
 * @param {PaymentPlanUpdateRequestParams} apiParams - The URL of the API endpoint to which the PATCH request will be sent.
 * @param {PaymentPlanAPIRequest} apiData - The data to be sent in the PATCH request body.
 * @returns {Promise<any>} A promise that resolves with the response data from the API.
 * @throws {Error} If there is an error during request.
 */
export const updatePaymentSchedule = async (
  apiParams: PaymentPlanUpdateRequestParams,
  apiData: PaymentPlanUpdateRequest,
) => {
  const apiURL = UPDATE_PAYMENT_PLAN_API_URL.replace(
    '{userId}',
    apiParams.userID.toString(),
  ).replace('{paymentPlanId}', apiParams.paymentPlanID);
  try {
    return await axiosServerless.patch(apiURL, apiData);
  } catch (error) {
    return Promise.reject(error?.response?.data || error);
  }
};

/**
 * Cancels a payment plan.
 * @param {PaymentPlanCancelRequestParams} apiParams - The URL of the API endpoint to which the PATCH request will be sent.
 * @param {PaymentPlanCancelRequest} apiData - The data to be sent in the PATCH request body.
 * @returns {Promise<any>} A promise that resolves with the response data from the API.
 * @throws {Error} If there is an error during request.
 */
export const cancelPaymentPlan = async (
  apiParams: PaymentPlanCancelRequestParams,
  apiData: PaymentPlanCancelRequest,
) => {
  try {
    const apiUrl =
      PAYMENT_SCHEDULE_API_URL.replace('{userId}', apiParams.userID) +
      '/' +
      apiParams.paymentPlanID;
    return await axiosServerless.patch(apiUrl, apiData);
  } catch (error) {
    return Promise.reject(error?.response?.data || error);
  }
};

/**
 * Add a new payment method by student.
 * @param {PaymentMethodCreateRequestParams} apiParams - Add payment method url.
 * @param {PaymentMethodCreateRequest} apiData - The data to be sent in the POST request body.
 * @returns {Promise<any>} A promise that resolves with the response data from the API.
 */
export const createPaymentMethod = async (
  apiParams: PaymentMethodCreateRequestParams,
  apiData: PaymentMethodCreateRequest,
) => {
  try {
    const apiUrl = PAYMENT_METHOD_API_URL.replace('{userId}', apiParams.userID);
    return await axiosServerless.post(apiUrl, apiData);
  } catch (error) {
    return Promise.reject(error?.response?.data || error);
  }
};

/**
 * Set payment method as default.
 * @param {DefaultPaymentMethodRequestParams} apiParams - The URL of the API endpoint to which the PATCH request will be sent.
 * @param {DefaultPaymentMethodRequest} apiData - The data to be sent in the PATCH request body.
 * @returns {Promise<any>} A promise that resolves with the response data from the API.
 */
export const setDefaultPaymentMethod = async (
  apiParams: DefaultPaymentMethodRequestParams,
  apiData: DefaultPaymentMethodRequest,
) => {
  try {
    let apiUrl = PAYMENT_METHOD_API_URL.replace('{userId}', apiParams.userID);
    apiUrl = apiUrl + `/${apiParams.paymentMethodID}`;
    return await axiosServerless.patch(apiUrl, apiData);
  } catch (error) {
    return Promise.reject(error?.response?.data || error);
  }
};

/**
 * Delete a payment method.
 * @param {deletePaymentMethodRequestParams} apiParams - The URL of the API endpoint to which the DELETE request will be sent.
 * @returns {Promise<any>} A promise that resolves with the response data from the API.
 */
export const deletePaymentMethod = async (
  apiParams: deletePaymentMethodRequestParams,
) => {
  try {
    const apiUrl =
      PAYMENT_METHOD_API_URL.replace('{userId}', apiParams.userID) +
      `/${apiParams.paymentMethodID}`;
    return await axiosServerless.delete(apiUrl);
  } catch (error) {
    return Promise.reject(error?.response?.data || error);
  }
};

/**
 * Set auto pay.
 * @param {autoPayRequestParams} apiParams - The URL of the API endpoint to which the PATCH request will be sent.
 * @param {autoPayRequest} apiData - The data to be sent in the PATCH request body.
 * @returns {Promise<any>} A promise that resolves with the response data from the API.
 */
export const setAutoPay = async (
  apiParams: autoPayRequestParams,
  apiData: autoPayRequest,
) => {
  try {
    const apiUrlAutoPay = PAYMENT_SCHEDULE_API_URL.replace(
      '{userId}',
      apiParams.userID,
    );
    const setAutoPayApiUrl = apiUrlAutoPay + `/${apiParams.paymentPlanID}`;

    return await axiosServerless.patch(setAutoPayApiUrl, apiData);
  } catch (error) {
    return Promise.reject(error?.response?.data || error);
  }
};

/**
 * Create charge invoice transaction.
 * @param {string} apiUrl - Create charge invoice transaction api url.
 * @param {any} apiData - The data to be sent in the POST request body.
 * @returns {Promise<any>} A promise that resolves with the response data from the API.
 */
export const chargeInvoice = async (apiUrl: string, apiData: any) => {
  try {
    return await axiosServerless.post(apiUrl, apiData);
  } catch (error) {
    return Promise.reject(error?.response?.data || error);
  }
};

/**
 * Makes a payment request
 * @async
 * @param {makePaymentRequest} apiData - The data to be sent along with the payment request.
 * @returns {Promise<any>} A Promise that resolves with the response from the API after the payment request is made.
 */
export const makePayment = async (apiData: makePaymentRequest) => {
  try {
    return await axiosServerless.post(PAYMENT_CHARGE_API_URL, apiData);
  } catch (error) {
    return Promise.reject(error?.response?.data || error);
  }
};

/**
 * Get transaction detail.
 * @param {string} apiUrl - Create charge invoice transaction api url.
 * @returns {Promise<any>} A promise that resolves with the response data from the API.
 */
export const getTransaction = async (apiUrl: string) => {
  try {
    return await axiosServerless.get(apiUrl);
  } catch (error) {
    return Promise.reject(error?.response?.data || error);
  }
};

/**
 * Create merchant.
 * @param {any} apiData - Create merchant request data.
 * @returns {Promise<any>} A promise that resolves with the response data from the API.
 */
export const createMerchant = async (apiData: any) => {
  try {
    return await axiosServerless.post(CREATE_MERCHANT_API_URL, apiData);
  } catch (error) {
    return Promise.reject(error?.response?.data || error);
  }
};

/**
 * send reminder.
 * @param {SendReminderRequestParams} apiParams - send reminder api url.
 * @returns {Promise<any>} A promise that resolves with the response data from the API.
 */
export const sendReminder = async (
  apiParams: SendReminderRequestParams,
  apiData: SendReminderRequest,
) => {
  try {
    const apiUrl = SEND_REMINDER.replace('{userID}', apiParams.userID);

    return await axiosServerless.post(apiUrl, apiData);
  } catch (error) {
    return Promise.reject(error?.response?.data || error);
  }
};

/**
 * Generate report.
 * @param {string} apiUrl - Report generate api url.
 * @returns {Promise<any>} A promise that resolves with the response data from the API.
 */
export const generateReport = async (apiData: ReportsAPIRequest) => {
  try {
    return await axiosServerless.post(REPORTS_API_URL, apiData);
  } catch (error) {
    return Promise.reject(error?.response?.data || error);
  }
};

/**
 * Initiates a refund for a specified transaction.
 * @param {string} transactionId - The ID of the transaction to be refunded.
 * @param {RefundTransactionAPIRequest} apiData - The data to be sent with the refund request.
 * @returns {Promise<any>} A promise that resolves with the response from the refund API.
 */
export const refundTransaction = async (
  transactionId: string,
  apiData: RefundTransactionAPIRequest,
) => {
  const apiUrl = REFUND_TRANSACTION_API_URL.replace(
    '{transactionID}',
    transactionId,
  );
  try {
    return await axiosServerless.post(apiUrl, apiData);
  } catch (error) {
    return Promise.reject(error?.response?.data || error);
  }
};

/**
 * Disable a payment plan.
 * @param {disablePaymentPlanRequest} apiData - The data to be sent with the disable payment plan request.
 * @returns {Promise<any>} A promise that resolves with the response from the API.
 */
export const disablePaymentPlan = async (
  apiData: disablePaymentPlanRequest,
) => {
  try {
    return await axiosServerless.patch(PAYMENT_PLAN_DISABLE_API_URL, apiData);
  } catch (error) {
    return Promise.reject(error?.response?.data || error);
  }
};

export const getActivePaymentPlanOfSelectedUsers = async (
  apiData: userActivePaymentPlansRequest,
) => {
  const { userIDs, programID } = apiData;
  try {
    return await axiosServerless.get(LIST_PAYMENT_PLAN_API_URL, {
      params: { isActive: true, programIDs: programID, userIDs },
    });
  } catch (error) {
    return Promise.reject(error?.response?.data || error);
  }
};

export const addTemplate = async (apiData: TemplateAPIRequest) => {
  try {
    const response = await axiosServerless.post(ADD_TEMPLATES, apiData);
    return response.data;
  } catch (error) {
    return Promise.reject(error?.response?.data || error);
  }
};

export const updateTemplate = async (
  apiData: TemplateAPIRequest,
  templateID: string,
) => {
  const apiUrl = UPDATE_TEMPLATES.replace('{templateID}', templateID);
  try {
    const response = await axiosServerless.patch(apiUrl, apiData);
    return response.data;
  } catch (error) {
    return Promise.reject(error?.response?.data || error);
  }
};

export const paymentScheduleBulkCreate = async (
  apiData: PaymentScheduleAPIRequest,
) => {
  try {
    return await axiosServerless.post(PAYMENT_SCHEDULE_BULK_CREATE, apiData);
  } catch (error) {
    return Promise.reject(error?.response?.data || error);
  }
};

export const updateDailyTransactionSummary = async (
  apiData: DailySummaryUpdateAPIRequest,
  userID: string,
) => {
  try {
    const apiUrl = UPDATE_DAILY_TRANSACTION_SUMMARY_API_URL.replace(
      '{userId}',
      userID,
    );
    return await axiosServerless.put(apiUrl, apiData);
  } catch (error) {
    return Promise.reject(error?.response?.data || error);
  }
};

export const getDailyTransactionSummary = async (userID: string) => {
  try {
    const apiUrl = GET_DAILY_TRANSACTION_SUMMARY_API_URL.replace(
      '{userId}',
      userID,
    );
    const response = await axiosServerless.get(apiUrl);
    return response.data.data;
  } catch (error) {
    return Promise.reject(error?.response?.data || error);
  }
};

export const deleteTemplate = async (templateID: string) => {
  const apiUrl = DELETE_TEMPLATE.replace('{templateID}', templateID);
  try {
    return await axiosServerless.delete(apiUrl);
  } catch (error) {
    return Promise.reject(error?.response?.data || error);
  }
};

export const getSchoolConfig = async (schoolID: string) => {
  try {
    const schoolPaymentConfigURL = GET_MERCHANT_PAYMENT_API_URL.replace(
      '{schoolID}',
      schoolID,
    );
    return await axiosServerless.get(schoolPaymentConfigURL);
  } catch (error) {
    return Promise.reject(error?.response?.data || error);
  }
};

/**
 * Initiates a refund for a specified transaction.
 * @param {string} transactionId - The ID of the transaction to be refunded.
 * @param {RefundPaymentLinkTransactionAPIRequest} apiData - The data to be sent with the refund request.
 * @returns {Promise<any>} A promise that resolves with the response from the refund API.
 */
export const refundPaymentLinkTransaction = async (transactionId: string) => {
  const apiUrl = REFUND_PAYMENT_LINK_TRANSACTION_API_URL.replace(
    '{transactionID}',
    transactionId!,
  );
  try {
    return await axiosServerless.post(apiUrl);
  } catch (error) {
    return Promise.reject(error?.response?.data || error);
  }
};

/**
 * Delete a transaction.
 * @param {string} transactionId - The ID of the transaction to be refunded.
 * @param {DeletePaymentLinkTransactionAPIRequest} apiData - The data to be sent with the delete request.
 * @returns {Promise<any>} A promise that resolves with the response from the delete API.
 */
export const deletePaymentLinkTransaction = async (transactionId: string) => {
  const apiUrl = DELETE_PAYMENT_LINK_TRANSACTION_API_URL.replace(
    '{transactionID}',
    transactionId!,
  );
  try {
    return await axiosServerless.delete(apiUrl);
  } catch (error) {
    return Promise.reject(error?.response?.data || error);
  }
};

/**
 * Get payment plans detail.
 * @param {string} paymentPlanID - The ID of the payment plan to fetch payment plans detail.
 * @returns {Promise<any>} A promise that resolves with the response from the API.
 */

export const getPaymentPlanDetail = async (paymentPlanID: string) => {
  const apiUrl = GET_LIST_PAYMENT_PLAN_API_URL.replace(
    '{paymentPlanID}',
    paymentPlanID,
  );
  try {
    return await axiosServerless.get(apiUrl);
  } catch (error) {
    return Promise.reject(error?.response?.data || error);
  }
};
