/* istanbul ignore else */
import { Error as ErrorIcon } from '@mui/icons-material';
import { alpha, createTheme } from '@mui/material/styles';

import { ThemeMode } from 'tools/constants/profile';

import { omit } from 'helpers/general';

import {
  background,
  blue1,
  blue10,
  blue6,
  blue7,
  blue8,
  blue9,
  gray1,
  gray15,
  gray18,
  gray2,
  gray3,
  gray4,
  gray5,
  gray6,
  gray7,
  gray8,
  gray9,
  green1,
  green10,
  green12,
  green13,
  green14,
  green15,
  green2,
  green20,
  green3,
  green4,
  green5,
  green6,
  primaryBlue,
  primaryGreen,
  primaryRed,
  primaryYellow,
  purple1,
  purple6,
  red1,
  red10,
  red11,
  red12,
  red13,
  red14,
  red15,
  red18,
  red3,
  red4,
  red5,
  red6,
  red9,
  white,
  yellow1,
  yellow6,
  yellow8,
} from './colors';

import type { ChipClasses } from '@mui/material/Chip';
import type { useTheme } from '@mui/material/styles';
import type { OverridesStyleRules } from '@mui/material/styles/overrides';

const desktopModeMediaQuery = '@media (min-width: 600px)';

// -------------------------------------------------------------------------------------------------
export const getHeadingColor = (isLight: boolean) => (isLight ? gray1 : white);

export const getDefaultBackgroundColor = (isLight: boolean) =>
  isLight ? background : gray1;

export const getPaperBackgroundColor = (isLight: boolean) =>
  isLight ? white : gray2;

// -------------------------------------------------------------------------------------------------
export const getTheme = (mode: ThemeMode.Dark | ThemeMode.Light) => {
  const isLight = mode === ThemeMode.Light;
  const lineColor = isLight ? gray8 : gray3;
  const lineWidth = '1px';
  const progressColor = isLight ? gray7 : gray3;

  const headingColor = getHeadingColor(isLight);
  const subtitleColor = isLight ? gray3 : gray8;
  const textColor = isLight ? gray4 : gray7;

  let theme = createTheme({
    palette: {
      background: {
        default: getDefaultBackgroundColor(isLight),
        paper: getPaperBackgroundColor(isLight),
      },
      completed: {
        contrastText: `${green13} !important`,
        main: `${green12} !important`,
      },
      current: {
        contrastText: `${green15} !important`,
        main: `${green14} !important`,
      },
      declined: {
        contrastText: `${red10} !important`,
        main: `${red9} !important`,
      },
      due: {
        contrastText: `${red15} !important`,
        main: `${red14} !important`,
      },
      error: {
        light: red6,
        main: primaryRed,
      },
      failed: {
        contrastText: `${red13} !important`,
        main: `${red12} !important`,
      },
      lineColor,
      loa: {
        contrastText: `${blue10} !important`,
        main: `${blue9} !important`,
      },
      mode,
      outstanding: {
        contrastText: `${red10} !important`,
        main: `${red9} !important`,
      },
      overdue: {
        contrastText: `${red10} !important`,
        main: `${red9} !important`,
      },
      paid: {
        contrastText: `${green15} !important`,
        main: `${green14} !important`,
      },
      partial: {
        contrastText: `${red11} !important`,
        main: `${yellow8} !important`,
      },
      pastdue: {
        contrastText: `${red11} !important`,
        main: `${yellow8} !important`,
      },
      primary: {
        contrastText: white,
        light: green6,
        main: primaryGreen,
      },
      primaryOutlined: {
        dark: green20,
        light: green10,
        main: primaryGreen,
      },
      refunded: {
        contrastText: `${blue8} !important`,
        main: `${blue7} !important`,
      },
      secondary: {
        light: blue6,
        main: primaryBlue,
      },
      text: {
        primary: isLight ? gray3 : white,
        secondary: isLight ? gray4 : gray7,
      },
      upcoming: {
        contrastText: `${gray18} !important`,
        main: `${gray15} !important`,
      },
      void: {
        contrastText: `${purple1} !important`,
        main: `${purple6} !important`,
      },
    },
    typography: {
      body1: {
        color: textColor,
        fontSize: '16px',
        lineHeight: '24px',
      },
      body2: {
        color: textColor,
        fontSize: '14px',
        lineHeight: '20px',
      },
      button: {
        fontSize: '15px',
        fontWeight: 700,
        letterSpacing: '0.015em',
        lineHeight: '24px',
        textTransform: 'unset',
      },
      caption: {
        color: textColor,
        display: 'block',
        fontSize: '12px',
        lineHeight: '20px',
      },
      fontFamily: '"Inter", "Helvetica Neue", "Arial", sans-serif',
      fontSize: 14,
      h1: {
        color: headingColor,
        fontSize: '28px',
        fontWeight: 600,
        lineHeight: '40px',
      },
      h2: {
        color: headingColor,
        fontSize: '24px',
        fontWeight: 'bold',
        letterSpacing: '0.005em',
        lineHeight: '32px',
      },
      h3: {
        color: headingColor,
        fontSize: '22px',
        fontWeight: 'bold',
        letterSpacing: '0.005em',
        lineHeight: '32px',
      },
      h4: {
        color: headingColor,
        fontSize: '18px',
        fontWeight: 'bold',
        letterSpacing: '0.005em',
        lineHeight: '28px',
      },
      h5: {
        color: headingColor,
        fontSize: '16px',
        fontWeight: 'bold',
        letterSpacing: '0.005em',
        lineHeight: '24px',
      },
      h6: {
        color: headingColor,
        fontSize: '14px',
        fontWeight: 600,
        letterSpacing: '0.005em',
        lineHeight: '20px',
      },
      overline: {
        color: textColor,
        fontSize: '12px',
        fontWeight: 'bold',
        letterSpacing: '0.08em',
        lineHeight: '16px',
      },
      subtitle1: {
        color: subtitleColor,
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '20px',
      },
      subtitle2: {
        color: subtitleColor,
        fontSize: '13px',
        fontWeight: 500,
        lineHeight: '20px',
      },
    },
  });

  const cardShadow = isLight
    ? '0px 2px 8px 0px rgba(37, 37, 41, 0.1)'
    : '0px 2px 8px #0E0E0F';
  const headerShadow = isLight
    ? '0px 0px 8px rgba(78, 88, 107, 0.12), 0px 0px 4px rgba(78, 88, 107, 0.1)'
    : '0px 0px 8px rgba(0, 0, 0, 0.9), 0px 0px 4px rgba(0, 0, 0, 0.9)';
  const drawerShadow = isLight
    ? '0px 0px 8px rgba(78, 88, 107, 0.12), 0px 0px 4px rgba(78, 88, 107, 0.1)'
    : '0px 0px 8px rgba(0, 0, 0, 0.9), 0px 0px 4px #000000';

  const cardHoverStyles = {
    boxShadow: isLight
      ? '0px 5px 10px rgba(78, 88, 107, 0.15), 0px 2px 6px rgba(78, 88, 107, 0.15)'
      : '0px 6px 12px #0F0F0F, 0px 2px 8px #0F0F0F',
    zIndex: 1,
  };

  const buttonOutlinedStyle = {
    '&.Mui-disabled': {
      border: `1px solid ${isLight ? gray7 : gray4}`,
      color: isLight ? gray7 : gray4,
    },
    '&:focus-visible': {
      backgroundColor: isLight ? gray6 : gray4,
    },
    '&:hover': {
      backgroundColor: isLight ? gray7 : gray3,
      border: `1px solid ${isLight ? gray7 : gray4}`,
      borderWidth: '1px',
    },
    backgroundColor: isLight ? white : gray2,
    border: `1px solid ${isLight ? gray7 : gray4}`,
    color: theme.palette.text.primary,
  };

  theme = {
    ...theme,
    components: {
      // accordions
      MuiAccordion: {
        defaultProps: {
          disableGutters: true,
        },
        styleOverrides: {
          root: {
            '&:before': {
              display: 'none',
            },
            borderBottomLeftRadius: '6px',
            borderBottomRightRadius: '6px',
            borderTopLeftRadius: '6px',
            borderTopRightRadius: '6px',
            overflow: 'hidden',
          },
          rounded: {
            '&.MuiPaper-elevation1': {
              boxShadow: cardShadow,
            },
            '&:first-of-type': {
              borderTopLeftRadius: '6px',
              borderTopRightRadius: '6px',
            },
            '&:last-child': {
              borderBottomLeftRadius: '6px',
              borderBottomRightRadius: '6px',
            },
            borderBottomLeftRadius: '6px',
            borderBottomRightRadius: '6px',
            borderTopLeftRadius: '6px',
            borderTopRightRadius: '6px',
          },
        },
        variants: [
          {
            props: { elevation: 0 },
            style: {
              '& .MuiAccordionSummary-root': {
                display: 'inline-flex',
                minHeight: 0,
                padding: 0,
              },
              backgroundColor: 'unset',
              lineHeight: 'normal',
            },
          },
        ],
      },
      MuiAccordionDetails: {
        styleOverrides: {
          root: {
            display: 'block',
            padding: 0,
          },
        },
      },
      MuiAccordionSummary: {
        styleOverrides: {
          content: {
            margin: 0,
          },
          root: {
            minHeight: '64px',
            padding: '0 32px',
          },
        },
      },

      // alerts
      MuiAlert: {
        defaultProps: {
          iconMapping: {
            error: <ErrorIcon fontSize="inherit" />,
            warning: <ErrorIcon fontSize="inherit" />,
          },
        },
        styleOverrides: {
          icon: {
            fontSize: '24px',
            marginBottom: '-2px',
            marginTop: '-2px',
          },
          root: {
            borderRadius: '8px',
            boxShadow: 'none',
          },
          standardError: {
            '& .MuiAlert-icon': {
              color: isLight ? primaryRed : red4,
            },
            backgroundColor: isLight ? red6 : gray1,
          },
          standardWarning: {
            '& .MuiAlert-icon': {
              color: yellow1,
            },
            backgroundColor: isLight ? yellow6 : gray1,
          },
        },
      },

      // app bar
      MuiAppBar: {
        styleOverrides: {
          root: {
            boxShadow: headerShadow,
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          root: {
            '& .MuiOutlinedInput-root': {
              minHeight: '40px',
              padding: '5px 16px',
            },
            '& .MuiOutlinedInput-root .MuiAutocomplete-input': {
              padding: '0',
            },

            // with adornments
            '& .MuiOutlinedInput-root.MuiInputBase-adornedEnd': {
              paddingRight: '36px',
            },

            // small autocomplete
            '& .MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
              minHeight: '32px',
              padding: '1px 16px',
            },
          },
        },
      },

      // buttons
      MuiButton: {
        defaultProps: {
          disableFocusRipple: true,
          disableTouchRipple: true,
          size: 'large',
          variant: 'contained',
        },
        styleOverrides: {
          contained: {
            '&.Mui-focusVisible': {
              boxShadow: 'none',
            },
            '&:active': {
              boxShadow: 'none',
            },
            '&:hover': {
              '@media (hover: none)': {
                boxShadow: 'none',
              },
              boxShadow: 'none',
            },
            boxShadow: 'none',
          },
          // @ts-ignore: Wait for MUI to fix Typescript warning about this
          containedError: {
            '&.Mui-disabled': {
              backgroundColor: isLight ? red5 : red3,
              color: isLight ? white : red4,
            },
            '&:focus-visible': {
              backgroundColor: red3,
            },
            '&:hover': {
              backgroundColor: red1,
            },
          },
          containedPrimary: {
            '&.Mui-disabled': {
              backgroundColor: isLight ? green5 : green3,
              color: isLight ? white : green4,
            },
            '&:focus-visible': {
              backgroundColor: green3,
            },
            '&:hover': {
              backgroundColor: green1,
            },
          },
          endIcon: {
            '&.MuiButton-iconSizeSmall': {
              '&> *:first-of-type': {
                fontSize: '16px',
              },
            },
            marginLeft: '4px',
          },
          outlined: buttonOutlinedStyle,
          outlinedError: {
            '&.Mui-disabled': {
              border: `1px solid ${isLight ? red4 : red18}`,
              color: isLight ? red4 : red18,
            },
            '&:focus-visible': {
              backgroundColor: isLight ? red6 : gray3,
            },
            '&:hover': {
              backgroundColor: isLight ? red6 : gray3,
              border: `1px solid ${theme.palette.error.main}`,
            },
            border: `1px solid ${theme.palette.error.main}`,
            color: theme.palette.error.main,
          },
          root: {
            boxShadow: 'none',
            boxSizing: 'border-box',
            fontWeight: 600,
            minHeight: '32px',
            padding: '0px 20px',
          },
          sizeLarge: {
            borderRadius: '10px',
            minHeight: '40px',
            padding: '0 24px',
          },
          sizeMedium: {
            borderRadius: '8px',
            minHeight: '32px',
            padding: '0px 16px',
          },
          sizeSmall: {
            ...omit(theme.typography.caption, ['color', 'display']),
            borderRadius: '8px',
            fontWeight: 'bold',
            minHeight: '24px',
            padding: '0px 12px',
          },
          startIcon: {
            '&.MuiButton-iconSizeSmall': {
              '&> *:first-of-type': {
                fontSize: '16px',
              },
              marginRight: '4px',
            },
            marginRight: '4px',
          },
          textError: {
            '&.Mui-disabled': {
              color: isLight ? red5 : gray4,
            },
            '&:focus-visible': {
              backgroundColor: red4,
              color: red1,
            },
            '&:hover': {
              backgroundColor: red5,
              color: red1,
            },
          },
          textPrimary: {
            '&.Mui-disabled': {
              color: isLight ? green4 : gray4,
            },
            '&:focus-visible': {
              backgroundColor: green4,
              color: green1,
            },
            '&:hover': {
              backgroundColor: green6,
              color: green1,
            },
          },
          textSecondary: {
            '&.Mui-disabled': {
              color: isLight ? gray7 : gray4,
            },

            '&:focus-visible': {
              backgroundColor: gray6,
            },
            '&:hover': {
              backgroundColor: isLight ? gray7 : gray3,
            },
            color: theme.palette.text.primary,
          },
        },
        variants: [
          {
            props: { variant: 'primaryOutlined' },
            style: ({ theme }) => ({
              ...buttonOutlinedStyle,
              '&:hover': {
                ...buttonOutlinedStyle['&:hover'],
                backgroundColor: isLight
                  ? theme.palette.primaryOutlined.light
                  : theme.palette.primaryOutlined.dark,
                borderColor: theme.palette.primaryOutlined.main,
              },
              borderColor: theme.palette.primaryOutlined.main,
              color: theme.palette.primaryOutlined.main,
            }),
          },
        ],
      },

      MuiButtonGroup: {
        styleOverrides: {
          contained: {
            borderRadius: '20px',
          },
        },
      },

      // checkbox
      MuiCheckbox: {
        defaultProps: {
          disableFocusRipple: true,
        },
        styleOverrides: {
          root: {
            '&.Mui-checked': {
              '&.Mui-disabled': {
                // checked and disabled
                color: isLight ? green4 : primaryGreen,
                opacity: isLight ? 1 : 0.5,
              },
            },
            '&.Mui-disabled': {
              color: isLight ? gray7 : gray3,
            },

            '&.Mui-focusVisible': {
              // checked and focused
              '&.Mui-checked': {
                backgroundColor: isLight ? green5 : gray4,
              },
              // unchecked and focused
              backgroundColor: isLight ? gray7 : gray4,
            },

            '&:hover': {
              // checked and hovered
              '&.Mui-checked': {
                backgroundColor: isLight ? green6 : gray3,
              },
              // unchecked and hovered
              backgroundColor: isLight ? gray8 : gray3,
            },

            color: isLight ? gray6 : gray5,
            padding: '8px',
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          // @ts-ignore: Wait for MUI to fix Typescript warning about this
          colorError: {
            '&:hover': {
              backgroundColor: isLight ? red6 : primaryRed,
            },
            backgroundColor: isLight ? red6 : primaryRed,
            color: isLight ? red1 : white,
          },
          colorPrimary: {
            '&:hover': {
              backgroundColor: isLight ? green6 : primaryGreen,
            },
            backgroundColor: isLight ? green6 : primaryGreen,
            color: isLight ? green1 : white,
          },
          colorSecondary: {
            '&:hover': {
              backgroundColor: isLight ? blue6 : primaryBlue,
            },
            backgroundColor: isLight ? blue6 : primaryBlue,
            color: isLight ? blue1 : white,
          },
          colorWarning: {
            '&:hover': {
              backgroundColor: isLight ? yellow6 : primaryYellow,
            },
            backgroundColor: isLight ? yellow6 : primaryYellow,
            color: isLight ? yellow1 : white,
          },
          root: {
            ...omit(theme.typography.h6, ['color']),
            '&:hover': {
              backgroundColor: isLight ? gray8 : gray4,
            },
            backgroundColor: isLight ? gray8 : gray4,
            borderRadius: '12px',
            boxSizing: 'border-box',
            color: isLight ? gray4 : white,
            display: 'inline-flex',
            height: 'unset',
            minHeight: '24px',
            minWidth: '64px',
          },
        },
      },
      MuiCircularProgress: {
        styleOverrides: {
          determinate: {
            color: progressColor,
          },
        },
      },

      // baseline
      MuiCssBaseline: {
        styleOverrides: {
          // show learnosity dialog container above mui dialogs
          '.cke_dialog_container': {
            zIndex: `${theme.zIndex.modal} !important`,
          },
          // show the first dialog exclusively over the others
          '.MuiDialog-root ~ .MuiDialog-root': {
            display: 'none',
          },
        },
      },

      // date pickers
      MuiDatePicker: {
        defaultProps: {
          allowSameDateSelection: true,
          desktopModeMediaQuery,
        },
      },
      MuiDateRangePickerDay: {
        styleOverrides: {
          root: {
            '&.MuiDateRangePickerDay-rangeIntervalDayHighlight': {
              background: isLight ? green5 : alpha(green1, 0.1),
            },
          },
        },
      },

      MuiDateTimePicker: {
        defaultProps: {
          allowSameDateSelection: true,
          desktopModeMediaQuery,
        },
      },

      // dialog
      MuiDialog: {
        defaultProps: {
          maxWidth: 'xs',
        },
        styleOverrides: {
          paper: {
            '&.MuiDialog-paperScrollBody': {
              [theme.breakpoints.down(theme.breakpoints.values.md)]: {
                maxWidth: 'unset',
              },
            },
            backgroundImage: 'unset',
            margin: theme.spacing(1),
          },
          paperFullWidth: {
            width: 'calc(100% - 16px)',
          },
          root: {
            '& .MuiBackdrop-root': {
              backgroundColor: isLight
                ? alpha(gray1, 0.7)
                : 'rgba(0, 0, 0, 0.5)',
            },
            '& .MuiDialog-paperWidthMd': {
              maxWidth: '960px',
            },
            '& .MuiDialog-paperWidthSm': {
              maxWidth: '784px',
            },
            '& .MuiDialog-paperWidthXs': {
              maxWidth: '640px',
            },
          },
        },
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            padding: `${theme.spacing(2.5)} ${theme.spacing(9)}`,
            [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
              padding: `${theme.spacing(3)} ${theme.spacing(4)}`,
            },
          },
          spacing: {
            '& > :not(:first-of-type)': {
              marginLeft: '16px',
            },
          },
        },
      },
      MuiDialogContent: {
        styleOverrides: {
          dividers: {
            padding: `${theme.spacing(6)} ${theme.spacing(9)}`,
            [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
              padding: `${theme.spacing(3)} ${theme.spacing(4)}`,
            },
          },
          root: {
            padding: `${theme.spacing(6)} ${theme.spacing(9)}`,
            [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
              padding: `${theme.spacing(3)} ${theme.spacing(4)}`,
            },
          },
        },
      },

      MuiDialogTitle: {
        styleOverrides: {
          root: {
            lineHeight: 1,
            padding: `${theme.spacing(3)} ${theme.spacing(9)}`,
            [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
              padding: `${theme.spacing(3)} ${theme.spacing(4)}`,
            },
          },
        },
      },

      // dividers
      MuiDivider: {
        styleOverrides: {
          root: {
            borderColor: lineColor,
            borderRightWidth: 'thin',
          },
        },
        variants: [
          {
            props: { variant: 'small' },
            style: {
              borderColor: isLight ? gray7 : gray4,
              borderWidth: '1px',
            },
          },
        ],
      },

      // drawer
      MuiDrawer: {
        styleOverrides: {
          docked: {
            boxShadow: drawerShadow,
            zIndex: theme.zIndex.appBar - 1,
          },
        },
      },

      // fabs
      MuiFab: {
        styleOverrides: {
          primary: {
            backgroundColor: primaryGreen,
            color: white,
          },
          root: {
            backgroundColor: white,
          },
        },
      },

      // form control
      MuiFormControlLabel: {
        styleOverrides: {
          label: {
            flex: 1,
            paddingBottom: '8px',
            paddingTop: '8px',
          },
          labelPlacementStart: {
            marginLeft: 0,
            width: '100%',
          },
          root: {
            alignItems: 'flex-start',
            marginLeft: '-8px',
            marginRight: 0,
          },
        },
      },

      MuiFormHelperText: {
        styleOverrides: {
          root: {
            '&.Mui-disabled': {
              color: isLight ? gray4 : gray7,
            },
            '&.Mui-error': {
              '&.Mui-disabled': {
                color: isLight ? gray4 : gray7,
              },
              color: isLight ? red1 : primaryRed,
            },
            '&.Mui-focused': {
              '&.Mui-error': {
                color: isLight ? red1 : primaryRed,
              },
              color: green1,
            },
            color: isLight ? gray4 : gray7,
            marginLeft: '16px',
          },
        },
      },

      // icon button
      MuiIconButton: {
        styleOverrides: {
          root: {
            color: isLight ? gray6 : gray5,
          },
          sizeSmall: {
            marginBottom: -4,
            marginTop: -4,
            padding: '4px',
            verticalAlign: 'text-bottom',
          },
        },
      },
      MuiInputBase: {
        variants: [
          {
            props: { size: 'large' },
            style: {
              '& .MuiOutlinedInput-input': {
                padding: '14px 16px',
              },
              '&.MuiAutocomplete-inputRoot.MuiOutlinedInput-root': {
                minHeight: '48px',
                padding: '9px 16px',
              },
            },
          },
        ],
      },

      MuiInputLabel: {
        styleOverrides: {
          root: {
            ...omit(theme.typography.subtitle2, ['color']),
            '&.Mui-disabled': {
              color: isLight ? gray6 : gray4,
            },
            '&.Mui-error': {
              color: isLight ? gray3 : gray8,
            },
            color: isLight ? gray3 : gray8,
            paddingBottom: '4px',
            position: 'relative',
            textAlign: 'left',
            transform: 'unset',
          },
        },
      },

      // linear progress
      MuiLinearProgress: {
        styleOverrides: {
          root: {
            backgroundColor: progressColor,
            borderRadius: '8px',
            height: '16px',
          },
        },
      },

      // links
      MuiLink: {
        defaultProps: {
          underline: 'hover',
        },
        styleOverrides: {
          root: {
            fontWeight: 600,
          },
        },
      },

      // lists
      MuiList: {
        variants: [
          {
            // @ts-ignore: Revisit logic for setting custom variant. Could create a new common component.
            props: { variant: 'nav' },
            style: {
              display: 'grid',
              gap: '4px',
              padding: 0,
            },
          },
        ],
      },
      MuiListItem: {
        styleOverrides: {
          divider: {
            '&:last-child': {
              borderBottom: 'unset',
            },
            borderColor: lineColor,
          },
        },
      },
      MuiListItemButton: {
        styleOverrides: {
          root: {
            '&.Mui-disabled': {
              filter: 'grayscale(100%)',
            },
            color: isLight ? gray3 : white,
            fontSize: '14px',
          },
        },
        variants: [
          {
            props: { variant: 'nav' },
            style: {
              '&.Mui-selected': {
                backgroundColor: isLight ? green6 : gray3,
                color: isLight ? green1 : primaryGreen,
                fontWeight: 'bold',
              },
              borderRadius: 6,
              minHeight: 40,
            },
          },
        ],
      },
      MuiListItemIcon: {
        styleOverrides: {
          root: {
            color: isLight ? gray6 : gray5,
            height: '20px',
            marginLeft: '-2px',
            minWidth: '34px',
            position: 'relative',
            top: '-2px',
          },
        },
      },

      MuiListItemText: {
        defaultProps: {
          primaryTypographyProps: {
            variant: 'subtitle1',
          },
          secondaryTypographyProps: {
            paddingTop: 0.5,
            variant: 'caption',
          },
        },
        styleOverrides: {
          inset: {
            paddingLeft: '36px',
          },
          root: {
            margin: 0,
          },
        },
      },

      // menu
      MuiMenu: {
        defaultProps: {
          variant: 'menu',
        },
        styleOverrides: {
          list: {
            display: 'grid',
            gap: '4px',
            maxHeight: '272px',
            padding: '12px 8px',
          },
          root: {
            '& hr': {
              margin: '0 -8px',
            },
          },
        },
      },

      MuiMenuItem: {
        defaultProps: {
          disableTouchRipple: true,
        },
        styleOverrides: {
          root: {
            ...omit(theme.typography.subtitle2, ['color']),
            '&.Mui-disabled': {
              backgroundColor: 'unset',
            },
            '&.Mui-focusVisible': {
              backgroundColor: isLight ? gray8 : gray4,
            },
            '&.Mui-selected': {
              '&.Mui-focusVisible': {
                backgroundColor: isLight ? green5 : gray4,
              },
              '&:hover': {
                backgroundColor: isLight ? green6 : gray3,
              },
              backgroundColor: isLight ? green6 : gray3,
            },
            '&:hover': {
              backgroundColor: isLight ? gray9 : alpha(gray3, 0.5),
            },
            borderRadius: '8px',
            color: isLight ? gray3 : gray8,
            padding: '8px 16px',
          },
        },
      },

      // outlined
      MuiOutlinedInput: {
        styleOverrides: {
          input: {
            ...omit(theme.typography.subtitle1, ['color']),
            '&.Mui-disabled': {
              color: isLight ? gray7 : gray4,
            },
            '&.MuiInputBase-inputAdornedEnd': {
              paddingRight: 0,
            },
            '&.MuiSelect-select': {
              minHeight: 'auto',
            },
            '&::placeholder': {
              color: isLight ? gray5 : gray6,
              opacity: 1,
            },
            color: isLight ? gray3 : gray8,
            height: 'auto',
            padding: '10px 16px',
          },
          root: {
            // outline color
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: isLight ? gray7 : gray3,
            },

            // icons
            '& .MuiSvgIcon-root': {
              color: isLight ? gray6 : gray5,
            },

            '& fieldset legend span:not(.notranslate)': {
              position: 'absolute',
            },
            // disabled input
            '&.Mui-disabled': {
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: isLight ? gray7 : gray3,
              },
              backgroundColor: isLight ? white : gray2,
            },

            // errored input
            '&.Mui-error:not(.Mui-disabled)': {
              // focused error input
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: primaryRed,
                borderWidth: '1px',
              },
              caretColor: primaryRed,
            },

            // focused input
            '&.Mui-focused': {
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: isLight ? green4 : primaryGreen,
                borderWidth: '1px',
              },
              caretColor: isLight ? primaryGreen : green3,
            },

            // adornments
            '&.MuiInputBase-adornedEnd': {
              paddingRight: '12px',
            },

            '&.MuiInputBase-multiline': {
              padding: 0,
            },

            // hovered input
            '&:hover': {
              '&:not(.Mui-error):not(.Mui-disabled) .MuiOutlinedInput-notchedOutline':
                {
                  borderColor: isLight ? green4 : primaryGreen,
                },
            },

            backgroundColor: isLight ? white : gray2,
            borderRadius: '8px',
            color: isLight ? gray3 : gray8,
            height: 'auto',
          },
          sizeSmall: {
            '& .MuiOutlinedInput-input': {
              padding: '6px 16px',
            },
          },
        },
      },

      // paper
      MuiPaper: {
        styleOverrides: {
          elevation0: {
            boxShadow: 'none',
          },
          outlined: {
            border: `${lineWidth} solid ${lineColor}`,
            boxShadow: 'none',
            cursor: 'default',
          },
          root: {
            backgroundImage: 'unset',
            boxShadow: cardShadow,
            textDecoration: 'unset',
          },
          rounded: {
            borderRadius: '10px',
          },
        },
        variants: [
          {
            props: { variant: 'drag' },
            style: cardHoverStyles,
          },
          {
            props: { role: 'button', variant: 'elevation' },
            style: {
              '&:hover': cardHoverStyles,
              cursor: 'pointer',
              position: 'relative',
            },
          },
          {
            props: { role: 'tooltip', variant: 'elevation' },
            style: cardHoverStyles,
          },
        ],
      },

      MuiPickersDay: {
        styleOverrides: {
          root: {
            '&.Mui-disabled': {
              color: isLight ? gray6 : gray4,
            },
            '&.MuiDateRangePickerDay-dayInsideRangeInterval': {
              '&:hover': {
                background: isLight ? green4 : alpha(green1, 0.2),
              },
              color: isLight ? gray1 : white,
            },
            '&.MuiPickersDay-today': {
              borderColor: green2,
            },
            '&:hover': {
              background: isLight ? green5 : alpha(green1, 0.1),
              borderColor: 'transparent!important',
            },
            color: isLight ? gray1 : white,
            fontWeight: 500,
          },
        },
      },

      // popover
      MuiPopover: {
        styleOverrides: {
          paper: {
            boxShadow: cardHoverStyles.boxShadow,
          },
        },
      },

      // radios
      MuiRadio: {
        defaultProps: {
          disableFocusRipple: true,
        },
        styleOverrides: {
          root: {
            '&.Mui-disabled': {
              '&.Mui-checked': {
                color: isLight ? green4 : primaryGreen,

                // TODO: talk with design team to avoid using opacity for themes
                opacity: isLight ? 1 : 0.5,
              },
              color: isLight ? gray7 : gray3,
            },
            '&.Mui-focusVisible': {
              '&.Mui-checked': {
                backgroundColor: isLight ? green5 : gray4,
              },
              backgroundColor: isLight ? gray7 : gray4,
            },
            '&:hover': {
              '&.Mui-checked': {
                backgroundColor: isLight ? green6 : gray3,
              },
              backgroundColor: isLight ? gray8 : gray3,
            },
            color: isLight ? gray6 : gray5,
            padding: '8px',
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          select: {
            minWidth: '80px',
          },
        },
      },

      // sliders
      MuiSlider: {
        styleOverrides: {
          mark: {
            display: 'none',
          },
          rail: {
            backgroundColor: background,
            borderRadius: 4,
            height: 8,
            opacity: 1,
          },
          root: {
            height: 8,
          },
          thumb: {
            '&:focus, &:hover, &.Mui-active': {
              boxShadow: 'inherit',
            },
            backgroundColor: white,
            border: '2px solid currentColor',
            height: 24,
            width: 24,
          },
          track: {
            borderRadius: 4,
            height: 8,
          },
          valueLabel: {
            left: 'calc(-50% + 4px)',
          },
        },
      },

      // stack
      MuiStack: {
        defaultProps: {
          spacing: 3,
        },
      },

      // steppers
      MuiStepConnector: {
        styleOverrides: {
          line: {
            borderColor: lineColor,
            borderWidth: '2px',
          },
        },
      },
      MuiStepContent: {
        styleOverrides: {
          root: {
            borderColor: gray8,
            borderWidth: '2px',
          },
        },
      },
      MuiStepIcon: {
        styleOverrides: {
          root: {
            color: gray6,
          },
        },
      },
      MuiStepper: {
        styleOverrides: {
          horizontal: {
            backgroundColor: isLight ? '' : theme.palette.background.paper,
            borderBottom: `1px solid ${lineColor}`,
          },
          root: {
            padding: '24px',
          },
        },
      },
      MuiSvgIcon: {
        styleOverrides: {
          colorAction: {
            color: isLight ? gray6 : gray5,
          },
          fontSizeSmall: {
            fontSize: '16px',
          },
        },
      },

      // switches
      MuiSwitch: {
        styleOverrides: {
          root: {
            marginBottom: '-7px',
            marginTop: '-7px',
          },
          switchBase: {
            '&.Mui-checked': {
              // checked track
              '&+.MuiSwitch-track': {
                backgroundColor: isLight ? green5 : primaryGreen,
                opacity: 1,
              },
              '&.Mui-disabled': {
                // disabled checked track
                '&+.MuiSwitch-track': {
                  backgroundColor: isLight ? green6 : green4,
                  opacity: 1,
                },
                // disabled checked thumb
                color: green5,
              },
              // checked thumb
              color: isLight ? primaryGreen : green6,
            },
            '&.Mui-disabled': {
              // disabled unchecked track
              '&+.MuiSwitch-track': {
                backgroundColor: isLight ? gray8 : gray4,
                opacity: 1,
              },
              // disabled unchecked thumb
              color: isLight ? white : gray5,
            },
            // unchecked thumb
            color: isLight ? white : gray8,
          },
          thumb: {
            boxShadow:
              '0px 0px 2px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.24)',
          },
          track: {
            backgroundColor: isLight ? gray6 : gray5,
            opacity: 1,
          },
        },
      },

      // tabs
      MuiTab: {
        styleOverrides: {
          root: {
            '& .Mui-selected': {
              color: theme.palette.text.primary,
            },
            fontWeight: 500,
            margin: '0 16px',
            minHeight: '56px',
            minWidth: 'unset',

            whiteSpace: 'pre',
          },
        },
      },

      // tables
      MuiTableCell: {
        styleOverrides: {
          head: {
            ...theme.typography.subtitle1,
            borderBottom: `1px solid ${lineColor}`,
            whiteSpace: 'pre',
          },
          root: {
            ...theme.typography.body2,
            '&.sticky-column': {
              boxShadow: drawerShadow,
            },
            backgroundColor: theme.palette.background.paper,
            borderBottom: `1px solid ${lineColor}`,
            whiteSpace: 'pre',
          },
          stickyHeader: {
            backgroundColor: theme.palette.background.paper,
            borderBottom: `${lineWidth} solid ${lineColor}`,
          },
        },
      },
      MuiTableRow: {
        styleOverrides: {
          root: {
            '&.MuiTableRow-hover:hover': {
              '&:hover': {
                cursor: 'pointer',
              },
            },
          },
        },
        variants: [
          {
            props: {
              // @ts-ignore: Some table rows can be dragged and thus would need custom styling.
              // We can set custom variants but Typescript will not understand it.
              variant: 'drag',
            },
            style: cardHoverStyles,
          },
        ],
      },

      // tabs
      MuiTabs: {
        styleOverrides: {
          indicator: {
            height: '6px',
          },
        },
      },

      // text-fields
      MuiTextField: {
        defaultProps: {
          InputLabelProps: {
            required: false,
            shrink: true,
          },
          SelectProps: {
            displayEmpty: true,
          },
          size: 'medium',
          variant: 'outlined',
        },
      },

      // time picker
      MuiTimePicker: {
        defaultProps: {
          allowSameDateSelection: true,
          desktopModeMediaQuery,
        },
      },

      // toggles
      MuiToggleButton: {
        styleOverrides: {
          root: {
            ...omit(theme.typography.h6, ['color']),
            // selected toggle
            '&.Mui-selected': {
              '&:hover': {
                backgroundColor: isLight ? green6 : gray1,
              },
              backgroundColor: isLight ? green6 : gray1,
              borderColor: isLight ? green4 : primaryGreen,
              color: isLight ? gray1 : primaryGreen,
            },
            '&:hover': {
              backgroundColor: isLight ? white : gray1,
            },
            backgroundColor: isLight ? white : gray1,
            border: `1px solid ${isLight ? gray7 : gray4}`,
            borderRadius: '6px',
            color: isLight ? gray5 : gray6,
            padding: '9px 20px',
          },
          sizeSmall: {
            padding: '5px 12px',
          },
        },
      },
      MuiToggleButtonGroup: {
        defaultProps: {
          exclusive: true,
        },
        styleOverrides: {
          groupedHorizontal: {
            '&.Mui-selected': {
              borderLeft: `1px solid ${isLight ? green4 : green1}`,
              zIndex: 1,
            },
            '&:not(:first-of-type)': {
              borderLeft: `1px solid ${isLight ? gray7 : gray4}`,
            },
          },
        },
      },

      // typography
      MuiTypography: {
        defaultProps: {
          variantMapping: {
            h1: 'h1',
            h4: 'h2',
            h5: 'h3',
            h6: 'p',
            overline: 'h2',
            subtitle1: 'p',
            subtitle2: 'p',
          },
        },
        styleOverrides: {
          overline: {
            display: 'block',
            marginBottom: '20px',
          },
        },
      },

      // To use client-side checking only
      // https://mui.com/components/use-media-query/#client-side-only-rendering
      MuiUseMediaQuery: {
        defaultProps: {
          noSsr: true,
        },
      },
    },
  };

  return theme;
};

// -------------------------------------------------------------------------------------------------
// Below are overrides for specifying custom sizes/variants.
declare module '@mui/material/styles' {
  interface Palette {
    completed: Palette['primary'];
    current: Palette['primary'];
    declined: Palette['primary'];
    due: Palette['primary'];
    failed: Palette['primary'];
    lineColor: string;
    loa: Palette['primary'];
    outstanding: Palette['primary'];
    overdue: Palette['primary'];
    paid: Palette['primary'];
    partial: Palette['primary'];
    pastdue: Palette['primary'];
    primaryOutlined: Palette['primary'];
    refunded: Palette['primary'];
    upcoming: Palette['primary'];
    void: Palette['primary'];
  }
  interface PaletteOptions {
    completed?: PaletteOptions['primary'];
    current?: PaletteOptions['primary'];
    declined?: PaletteOptions['primary'];
    due?: PaletteOptions['primary'];
    failed?: PaletteOptions['primary'];
    lineColor: string;
    loa?: PaletteOptions['primary'];
    outstanding?: PaletteOptions['primary'];
    overdue?: PaletteOptions['primary'];
    paid?: PaletteOptions['primary'];
    partial?: PaletteOptions['primary'];
    pastdue?: PaletteOptions['primary'];
    primaryOutlined?: PaletteOptions['primary'];
    refunded?: PaletteOptions['primary'];
    upcoming?: PaletteOptions['primary'];
    void: PaletteOptions['primary'];
  }
}

declare module '@mui/material/Chip' {
  // Our design system does not allow for a small size chip.
  // Will throw Typescript warning if specifying size="small".
  interface ChipPropsSizeOverrides {
    small?: false;
  }

  interface ChipPropsColorOverrides {
    completed: true;
    current: true;
    declined: true;
    due: true;
    failed: true;
    loa: true;
    outstanding: true;
    overdue: true;
    paid: true;
    partial: true;
    pastdue: true;
    refunded: true;
    upcoming: true;
    void: true;
  }

  interface CustomChipStyleOverrides
    extends Partial<
      OverridesStyleRules<keyof ChipClasses, 'MuiChip', typeof useTheme>
    > {
    colorOutstanding: {
      '&:hover': {
        backgroundColor: string;
      };
      backgroundColor: string;
      color: string;
    };
  }
}

declare module '@mui/material/Paper' {
  interface PaperPropsVariantOverrides {
    drag: true;
  }
}

declare module '@mui/material/InputBase' {
  interface InputBasePropsSizeOverrides {
    large: true;
  }
}

declare module '@mui/material/ListItemButton' {
  interface ListItemButtonBaseProps {
    variant?: 'nav';
  }
}

declare module '@mui/material/TextField' {
  interface TextFieldPropsSizeOverrides {
    large: true;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    primaryOutlined: true;
  }
}

declare module '@mui/material/Divider' {
  interface DividerPropsVariantOverrides {
    small: true;
  }
}
