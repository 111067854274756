import { CKQuestionType, QuestionType } from 'tools/constants/question';

type Question = {
  custom_metadata?: {
    sub_type: string;
  };
  options?: any;
  type: string;
};

// Determines if Learnosity question is a true/false question type
export const isTrueFalseQuestion = (question: Question) => {
  if (question.type && question.options) {
    const questionType = question.type;
    if (questionType === QuestionType.MultipleChoice) {
      if (question.custom_metadata) {
        if (question.custom_metadata.sub_type === 'TF') {
          return true;
        }
      }
    }
  }

  return false;
};

// Generates labels for question "cards"
export const getFormattedLabel = (question: Question) => {
  const type = question.type;
  switch (type) {
    case QuestionType.MultipleChoice: {
      if (isTrueFalseQuestion(question)) {
        return 'TF';
      }
      return 'MC';
    }

    case QuestionType.Essay:
      return 'LONG';

    case QuestionType.ShortAnswer:
      return 'SHORT';

    case QuestionType.Math:
      return 'MATH';

    case QuestionType.FillBlank:
      return 'FILL-IN';

    case QuestionType.Match:
      return 'MATCH';

    // TODO: purge highlight
    case QuestionType.Highlight:
    case QuestionType.Pen:
      return 'PEN';

    case QuestionType.Matrix:
      return 'MATRIX';

    default:
      return '';
  }
};

// —————————————————————————————————————————————————————————————————————————————————————————————————
// Question/Widget Initializations
// —————————————————————————————————————————————————————————————————————————————————————————————————
// Maps certain basic Learnosity's question types to custom tags/names
export const getCustomQuestionType = (question: Question) => {
  if ('type' in question) {
    switch (question.type) {
      case QuestionType.MultipleChoice: {
        if (isTrueFalseQuestion(question)) {
          return CKQuestionType.TrueFalse;
        }
        return CKQuestionType.MultipleChoice;
      }

      case QuestionType.Essay:
        return CKQuestionType.Essay;

      case QuestionType.ShortAnswer:
        return CKQuestionType.ShortAnswer;

      case QuestionType.Math:
        return CKQuestionType.Math;

      case QuestionType.FillBlank:
        return CKQuestionType.FillBlank;

      case QuestionType.Match:
        return CKQuestionType.Match;

      // highlight is deprecated
      case QuestionType.Highlight:
        return CKQuestionType.Highlight;

      case QuestionType.Pen:
        return CKQuestionType.Pen;

      case QuestionType.Matrix:
        return CKQuestionType.Matrix;

      default:
        return '';
    }
  }

  throw new Error('Not a valid question.');
};

// —————————————————————————————————————————————————————————————————————————————————————————————————
// Editor Initialization
// —————————————————————————————————————————————————————————————————————————————————————————————————
// Configure view for question editor, and hide certain fields if necessary
const hiddenFields = [
  'instant_feedback',
  'feedback_attempts',
  'metadata',
  'is_math',
  'math_renderer',
  'submit_over_limit',
  'validation.unscored',
  'ui_style.show_drag_handle',
];

const hiddenSections = ['details'];

// shows/hides certain fields, changes label names if necessary, and more
const questionTypeTemplates = {
  [QuestionType.MultipleChoice]: [
    {
      defaults: {
        feedback_attempts: 'unlimited',
        is_math: true,

        multiple_responses: false,
        options: [
          {
            label: 'First Option',
            value: '0',
          },
          {
            label: 'Second Option',
            value: '1',
          },
          {
            label: 'Third Option',
            value: '2',
          },
          {
            label: 'Fourth Option',
            value: '3',
          },
        ],
        shuffle_options: false,
        stimulus: '<p>This is a multiple choice question.</p>',
        type: QuestionType.MultipleChoice,
        ui_style: {
          choice_label: 'upper-alpha',
          fontsize: 'normal',
          orientation: 'horizontal',
          type: 'block',
        },
        validation: {
          penalty: 0,
          scoring_type: 'exactMatch',
        },
      },
      hidden: ['validation.automarkable'],
      hidden_sections: ['layout'],
      name: 'Multiple Choice',
      reference: CKQuestionType.MultipleChoice,
    },
    {
      defaults: {
        custom_metadata: {
          sub_type: 'TF',
        },
        feedback_attempts: 'unlimited',
        is_math: false,
        multiple_responses: false,
        options: [
          {
            label: 'True',
            value: '1',
          },
          {
            label: 'False',
            value: '0',
          },
        ],
        shuffle_options: false,
        stimulus: '<p>This is a true/false question.</p>',
        type: QuestionType.MultipleChoice,
        ui_style: {
          choice_label: 'upper-alpha',
          fontsize: 'normal',
          orientation: 'horizontal',
          type: 'horizontal',
        },
        validation: {
          penalty: 0,
          scoring_type: 'exactMatch',
        },
      },
      hidden: [
        'validation.automarkable',
        'options',
        'multiple_responses',
        'shuffle_options',
      ],
      hidden_sections: ['layout', 'options.button.add'],
      name: 'True or False',
      reference: CKQuestionType.TrueFalse,
    },
  ],
  [QuestionType.Essay]: [
    {
      defaults: {
        feedback_attempts: 'unlimited',
        is_math: true,
        max_length: 1000,
        placeholder: 'Type response here.',
        show_copy: false,
        show_cut: false,
        show_paste: false,
        stimulus: '<p>This is an essay question.</p>',
        type: QuestionType.Essay,
        validation: {
          max_score: 1,
        },
      },
      hidden: ['validation.automarkable', 'submit_over_limit'],
      hidden_sections: ['layout'],
      name: 'Essay',
      reference: CKQuestionType.Essay,
    },
  ],
  [QuestionType.ShortAnswer]: [
    {
      defaults: {
        stimulus: '<p>This is a short answer question.</p>',
        type: QuestionType.ShortAnswer,
        validation: {
          scoring_type: 'exactMatch',
          valid_response: {
            score: 1,
            value: '',
          },
        },
      },
      hidden_sections: ['layout'],
      name: 'Short Answer',
      reference: CKQuestionType.ShortAnswer,
    },
  ],
  [QuestionType.Math]: [
    {
      defaults: {
        feedback_attempts: 'unlimited',
        is_math: true,
        placeholder: 'Type response here.',
        stimulus: 'This is a math question.',
        template: '\\(2\\times2=\\) {{response}}',
        type: QuestionType.Math,
        ui_style: {
          type: 'floating-keyboard',
        },
        validation: {
          scoring_type: 'exactMatch',
          valid_response: {
            score: 1,
            value: [
              [
                {
                  method: 'equivSymbolic',
                  options: {
                    decimalPlaces: 10,
                    ignoreText: true,
                  },
                  value: '4',
                },
              ],
            ],
          },
        },
      },
      hidden: [
        'validation.automarkable',
        'validation.valid_response.value[0].aria_label',
        'ui_style.type',
        'ui_style.keyboard_below_response_area',
        'numberPad',
      ],
      hidden_sections: ['layout', 'text_blocks.heading'],
      name: 'Math Formula',
      reference: CKQuestionType.Math,
    },
  ],
  [QuestionType.FillBlank]: [
    {
      defaults: {
        case_sensitive: false,
        feedback_attempts: 'unlimited',
        is_math: true,
        max_length: 60,
        stimulus: '<p>This is a fill-in-the-blank question.</p>',
        template:
          '<p>A question with fill in the blanks. &nbsp;​{{response}}&nbsp;was&nbsp;{{response}}&nbsp;.</p>',
        type: QuestionType.FillBlank,
        validation: {
          scoring_type: 'exactMatch',
          valid_response: {
            score: 1,
            value: ['answer', 'answer'],
          },
        },
      },
      hidden: [
        'validation.automarkable',
        'validation.rounding',
        'validation.match_all_possible_responses',
      ],
      hidden_sections: ['layout'],
      reference: CKQuestionType.FillBlank,
    },
  ],
  [QuestionType.Match]: [
    {
      defaults: {
        feedback_attempts: 'unlimited',
        is_math: true,
        possible_responses: ['[Choice A]', '[Choice B]', '[Choice C]'],
        stimulus: '<p>This is a matching problem.</p>',
        stimulus_list: ['[Stem 1]', '[Stem 2]', '[Stem 3]'],
        type: QuestionType.Match,
        ui_style: {
          show_drag_handle: false,
        },
        validation: {
          scoring_type: 'exactMatch',
          valid_response: {
            score: 1,
            value: ['[Choice C]', '[Choice B]', '[Choice A]'],
          },
        },
      },
      hidden: ['validation.automarkable', 'group_possible_responses'],
      hidden_sections: ['layout'],
      name: 'Matching',
      reference: CKQuestionType.Match,
    },
  ],
  [QuestionType.Pen]: [
    {
      defaults: {
        drawing_tools: ['scribble', 'clear'],
        image: {
          height: 0,
          source: '',
          width: 425,
        },
        line_color: ['rgba(0,0,0,1)'],
        line_width: 2,
        stimulus: '<p>This is a pen question.</p>',
        type: QuestionType.Pen,
      },
      hidden: [
        'character_map',
        'drawing_tools',
        'validation.automarkable',
        'validation.max_score',
        'ui_style.fontsize',
      ],
      name: 'Pen',
      reference: CKQuestionType.Pen,
    },
  ],
  // FIXME: Deprecated, need BE migration
  [QuestionType.Highlight]: [
    {
      defaults: {
        image: {
          height: 0,
          source: '',
          width: 425,
        },
        img_src: '',
        line_color: ['rgba(0,0,0,1)'],
        line_width: 2,
        stimulus: '<p>This is a pen question.</p>',
        type: QuestionType.Highlight,
      },
      hidden: [
        'validation.automarkable',
        'validation.max_score',
        'ui_style.fontsize',
      ],
      name: 'Pen',
      reference: CKQuestionType.Highlight,
    },
  ],
  [QuestionType.Matrix]: [
    {
      defaults: {
        options: ['True', 'False'],
        stems: ['[Stem 1]', '[Stem 2]', '[Stem 3]', '[Stem 4]'],
        stimulus: '<p>This is a matrix question.</p>',
        type: QuestionType.Matrix,
      },
      hidden: [
        'validation.automarkable',
        'validation.max_score',
        'ui_style.fontsize',
      ],
      hidden_sections: ['layout'],
      name: 'Matrix',
      reference: CKQuestionType.Matrix,
    },
  ],
};

// Initializes question editor with certain views
export const getAuthorInit = ({
  mode = 'item_edit',
  reference = false,
}: {
  mode: string;
  reference: string | boolean | undefined;
}) => ({
  request: {
    config: {
      dependencies: {
        question_editor_api: {
          init_options: {
            base_question_type: {
              hidden: hiddenFields,
              hidden_sections: hiddenSections,
            },
            label_bundle: {
              // Turning debug to 'true' lets you see properties you can hide/show/exclude/etc from the view
              // debug: true,
              'validation.min_score_if_attempted': 'Participation point(s)',
            },
            question_type_templates: questionTypeTemplates,
            ui: {
              help_button: true,
              layout: {
                mode: 'advanced',
              },
              source_button: false,
            },
            widget_type: 'response',
          },
        },
      },
      item_edit: {
        item: {
          back: false,
          popup_content: {
            enable: false,
          },
          reference: {},
          save: {
            persist: true,
            show: false,
          },
          widget: {
            delete: false,
            edit: false,
          },
        },
      },
      widget_templates: {
        back: false,
        save: false,
      },
    },
    mode,
    reference,
  },
});
