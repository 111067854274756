/**
 * Learnosity introduced asynchronous scoring in version 2023.2.LTS for
 * certain question types. Our implementation will continue to work synchronously,
 * but if we introduce any async scoring question types, we need to update the
 * methods outlined in the article below to support async scoring (e.g. ItemsApp
 * in src/types/learnosity.ts).
 *
 * https://help.learnosity.com/hc/en-us/articles/11463229618461-Introduction-to-Asynchronous-Scoring
 */
export enum QuestionType {
  Essay = 'plaintext',
  FillBlank = 'clozetext',
  Highlight = 'highlight',
  Match = 'association',
  Math = 'clozeformula',
  Matrix = 'choicematrix',
  MultipleChoice = 'mcq',
  Pen = 'drawing',
  ShortAnswer = 'shorttext',
  TrueFalse = 'mcq',
}

export enum CKQuestionType {
  Essay = 'ckEssay',
  FillBlank = 'ckFill',
  Highlight = 'ckHighlight',
  Match = 'ckMatch',
  Math = 'ckMath',
  Matrix = 'ckMatrix',
  MultipleChoice = 'ckMCQ',
  Pen = 'ckDrawing',
  ShortAnswer = 'ckShort',
  TrueFalse = 'ckTrueFalse',
}
